/* Headers */

.header-xs {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.header-sm {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
}

.header-md {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
}

.header-lg {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.0025em;
}

.header-xl {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.00625em;
}

.header-xxl {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.0075em;
}

/* Body */

.body-xs {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.body-sm {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
}

.body-md {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
}

.body-lg {
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.0025em;
}
.body-xl {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.005em;
}
.body-xxl {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.00625em;
}
.body-xxxl {
  margin: 0;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.0075em;
}

/* Label */

.label-xs {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: #0f172a;
}

.label-sm {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  color: #0f172a;
}

.label-md {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  color: #0f172a;
}

/* Button */

.button-xs {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
}

.button-sm {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
}

.button-md {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
}
